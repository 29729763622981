<template>
  <div>
    <el-dialog
      title="双/箱信息查看"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form ref="styleForm" :model="styleForm" label-width="80px" style="display: flex;">
        <div class="el-lt" style="width:85%;">
          <el-row :span="24">
            <el-col :span="8">
              <el-form-item label="Color" :class="$i18n.locale" prop="colorIds">
                <el-select v-model="styleForm.colorIds" clearable filterable multiple id:placeholder="$t('page.selectPlaceholder')">
                  <el-option
                    v-for="item in colorOptions"
                    :key="item.id"
                    :label="item.colorName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Size" :class="$i18n.locale" prop="sizeIds">
                <el-select v-model="styleForm.sizeIds" clearable filterable multiple id:placeholder="$t('page.selectPlaceholder')">
                  <el-option
                    v-for="item in sizeOptions"
                    :key="item.id"
                    :label="item.size"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Sku" prop="sku">
                <el-input v-model="styleForm.sku" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row class="el-rt" style="width:25%;">
          <el-col class="row-center">
            <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
            <!-- :loading="Loading"  -->
            <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          </el-col>
        </el-row>
      </el-form>
      <Table
        ref="multipleTable"
        :table-data="tableDatas"
        max-height="500px"
        :columns="styleDetailsColumns"
        border
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryStyleList, querySizeList, queryColorList } from '@/api/scm-api'
import { skuBoxInfo } from '@/api/package'
import { styleDetailsColumns } from './columns'
import Table from '@/components/Table'
export default {
  components: { Table },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    getStyleInfo: {
      type: Object,
      default: () => {}
    },
    styleDatas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      styleDetailsColumns,
      colorOptions: [],
      sizeOptions: [],
      styleForm: {
        colorIds: '',
        sizeIds: '',
        sku: ''
      },
      tableDatas: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(val) {
      if (!val) {
        this.$refs.styleForm?.resetFields()
      }
    },
    'styleDatas'(val) {
      if (val) {
        this.tableDatas = this.styleDatas
        this._StyleChange()
      }
    }
  },
  created() {

  },
  mounted() {
    // this._StyleChange()
    // this.queryClick()
  },
  methods: {
    async queryClick() {
      Object.assign(this.styleForm, { styleIds: [this.getStyleInfo.styleId] || [] })
      const { datas } = await skuBoxInfo(this.styleForm)
      this.tableDatas = datas
    },
    resetClick() {
      this.$refs.styleForm.resetFields()
      this.queryClick()
    },
    async _StyleChange() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
      const styleId = this.getStyleInfo.styleId
      const data = this.styleOptions.find(item => item.id === styleId) || []
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">
.inline {
  display: inline-block !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
