<template>
  <div>
    <el-dialog
      title="修改包装信息"
      :visible.sync="visible"
      width="80%"
      :close-on-click-modal="false"
    >
      <el-form ref="modifyForm" :model="form" :rules="modifyRules">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item label="包装代码" prop="packagingCodeId">
              <el-select
                v-model="form.packagingCodeId"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="(item,index) in packagingCodeOptions"
                  :key="index"
                  :label="item.packagingCode"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="内里标" prop="insideLabelDict">
              <el-select
                v-model="form.insideLabelDict"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
                @change="getImg"
              >
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_INSIDE_LABEL')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-image v-if="Img1" class="mr-5" :src="Img1" style="width:240px;height:350px" />
      <el-image v-if="Img2" :src="Img2" style="width:350px;height:350px" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmit">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryStyleList } from '@/api/scm-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { updateStylePackaging, getPackagingCode, insideLabel } from '@/api/package'
export default {
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    styleIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: { packagingCodeId: null, insideLabelDict: '' },
      modifyRules: { packagingCodeId: [{ required: true, message: '必填', trigger: ['change'] }] },
      loading: false,
      packagingCodeOptions: [],
      styleOptions: [],
      Img1: '',
      Img2: ''
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(val) {
      if (!val) {
        this.$refs.modifyForm?.clearValidate()
        this.$refs.modifyForm?.resetFields()
        this.Img1 = ''
        this.Img2 = ''
      }
    }
  },
  created() {

  },
  mounted() {
    this._getPackagingCode()
    this._queryStyleList()
  },
  methods: {
    async getImg() {
      if (!this.form.insideLabelDict) {
        this.Img1 = ''
        this.Img2 = ''
        return
      }
      const { datas } = await insideLabel({ insideLabelDict: this.form.insideLabelDict })
      this.Img1 = datas?.[0]
      this.Img2 = datas?.[1]
    },
    handleSubmit() {
      this.$refs.modifyForm.validate(async(valid) => {
        if (valid) {
          try {
            this.loading = true
            // 调用接口
            const params = {}
            const { packagingCodeId } = this.form
            const packagingCode = this.packagingCodeOptions.find(item => item.id === packagingCodeId)?.packagingCode

            const styles = this.styleIds.map(item => {
              const styleName = this.styleOptions.find(i => i.id === item)?.styleName
              return Object.assign({ styleId: item, styleName })
            })
            Object.assign(params, this.form, { styles, packagingCode })
            const { datas } = await updateStylePackaging(params)
            const skuList = datas?.map(i => i.skuName)
            const arr = datas?.map(item => { const styleName = this.styleOptions.find(i => i.id === item.styleId)?.styleName; return styleName })
            const styleNameList = [...new Set(arr)]
            if (Array.isArray(skuList) && skuList.length > 0) this.$message.warning({ dangerouslyUseHTMLString: true, message: `style:${styleNameList.join(',')};</br>sku:${skuList.join(',')}没有维护双箱数，请及时维护` })
            this.loading = false
            this.$notify({
              title: '操作成功',
              message: '操作成功',
              type: 'success'
            })
            this.$emit('update')
            this.$emit('input', false)
          } finally {
            this.loading = false
          }
        }
      })
    },
    async _getPackagingCode() {
      const { datas } = await getPackagingCode()
      this.packagingCodeOptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">
.inline {
  display: inline-block !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
