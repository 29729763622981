<template>
  <div>
    <vxe-modal
      v-model="visible"
      title="修改双/箱信息"
      width="60%"
      :before-hide-method="closed"
      :esc-closable="false"
    >

      <el-steps :active="active" align-center finish-status="success">
        <el-step title="修改双/箱信息" />
        <el-step title="修改双/箱信息确认" />
      </el-steps>
      <main class="upload-order-content">
        <section v-show="active === 0" class="import-orders">
          <el-form ref="styleForm" :model="styleForm" label-width="80px" style="display: flex;" class="mt-5">
            <div class="el-lt" style="width:85%;">
              <el-row :span="24">
                <el-col :span="8">
                  <el-form-item label="Color" :class="$i18n.locale" prop="colorIds">
                    <el-select v-model="styleForm.colorIds" clearable multiple filterable id:placeholder="$t('page.selectPlaceholder')">
                      <el-option
                        v-for="item in colorOptions"
                        :key="item.id"
                        :label="item.colorName"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Size" :class="$i18n.locale" prop="sizeIds">
                    <el-select v-model="styleForm.sizeIds" clearable multiple filterable id:placeholder="$t('page.selectPlaceholder')">
                      <el-option
                        v-for="item in sizeOptions"
                        :key="item.id"
                        :label="item.size"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Sku" prop="sku">
                    <el-input v-model="styleForm.sku" :placeholder="$t('page.inputPlaceholder')" />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row class="el-rt" style="width:25%;">
              <el-col class="row-center">
                <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
                <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
              </el-col>
            </el-row>
          </el-form>
          <div class="mb-2">
            一键赋值
            <el-switch v-model="newValue" />
          </div>
          <vxe-table
            ref="xTable"
            :data="tableDatas"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            :edit-rules="validRules"
            max-height="400px"
          >
            <vxe-column field="sku" title="SKU" />
            <vxe-column field="color" title="颜色" />
            <vxe-column field="size" title="尺码" />
            <vxe-column field="cartonNumber" title="双/箱" :edit-render="{}">
              <template #default="{ row }">
                <vxe-select v-model="row.cartonNumber" :transfer="true" @change="getCartonNumber">
                  <vxe-option v-for="item in _getAllCommodityDict('CARTON_QUANTITY')" :key="item.val" :value="item.val" :label="item.label" />
                </vxe-select>
              </template>

              <template #edit="{ row }">
                <vxe-select v-model="row.cartonNumber" :transfer="true" @change="getCartonNumber">
                  <vxe-option v-for="item in _getAllCommodityDict('CARTON_QUANTITY')" :key="item.val" :value="item.val" :label="item.label" />
                </vxe-select>
              </template>
            </vxe-column>
          </vxe-table>
        </section>
        <section v-show="active === 1" class="import-orders">
          <Table
            ref="multipleTable"
            class="mt-5 mb-2"
            :table-data="styleDatas"
            max-height="500px"
            :columns="styleDetailsColumns"
            border
            :loading="loading"
          />
        </section>
      </main>

      <el-row class="mt-3" type="flex" justify="end">
        <el-button v-show="active === 0" style="margin-top: 12px;" @click="closed">关闭</el-button>
        <el-button v-show="active === 0" style="margin-top: 12px;" :loading="loading" @click="toNext">下一步</el-button>
        <el-button v-show="active === 1" style="margin-top: 12px;" :loading="loading" @click="toReview">上一步</el-button>
        <el-button v-show="active === 1" style="margin-top: 12px;" :loading="loading" @click="handleSubmit">确认提交</el-button>
      </el-row>

    </vxe-modal>
  </div>
</template>

<script>
import { queryStyleList, querySizeList, queryColorList } from '@/api/scm-api'
import { styleDetailsColumns } from './columns'
import { skuBoxInfo, checkingInfo, commitInfo } from '@/api/package'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Table from '@/components/Table'
export default {
  components: { Table },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    modifyStyleInfo: {
      type: Object,
      default: () => {}
    },
    modifyStyleDatas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      styleDetailsColumns,
      active: 0,
      validRules: {
        cartonNumber: [{ required: true, message: this.$t('page.inputPlaceholder') }]
      },
      styleDatas: [],
      tableDatas: [],
      colorOptions: [],
      sizeOptions: [],
      styleForm: {
        colorIds: '',
        sizeIds: '',
        sku: ''
      },
      newValue: true,
      loading: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(val) {
      if (!val) {
        this.$refs.styleForm?.resetFields()
      }
    },
    'modifyStyleDatas'(val) {
      if (val) {
        this.tableDatas = this.modifyStyleDatas
        this._StyleChange()
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    closed() {
      this.$emit('input', false)
      this.active = 0
    },
    getCartonNumber(val) {
      if (this.newValue) {
        this.tableDatas.map(item => Object.assign(item, { cartonNumber: val.value }))
      }
    },
    async toNext() {
      if (!this.tableDatas || this.tableDatas.length === 0) return this.$message.warning('无可用数据')
      const $table = this.$refs.xTable
      const errMap = await $table.validate(true).catch(errMap => errMap)
      if (!errMap) {
        try {
          this.loading = true
          this.styleDatas = []
          const { datas } = await checkingInfo(this.tableDatas)
          if (datas) {
            const { unPassSku, passSkuCartonVO } = datas
            if (unPassSku) { this.$message.warning(`SKU:${unPassSku.join('、')}有审批中、已生效、已分配生产、已转收货、已完成、缺量收货的采购订单，不允许修改箱规`) }
            this.styleDatas = passSkuCartonVO
          }
          this.active = 1
        } finally {
          this.loading = false
        }
      }
    },
    toReview() {
      this.active = 0
    },
    async handleSubmit() {
      try {
        this.loading = true
        if (!this.styleDatas || this.styleDatas.length === 0) return this.$message.warning('无可用数据')
        this.styleDatas.map(item => Object.assign(item, { styleId: this.modifyStyleInfo.styleId }))
        await commitInfo(this.styleDatas)
        this.$emit('input', false)
        this.$emit('update')
        this.loading = false
        this.active = 0
      } finally {
        this.loading = false
      }
    },
    async queryClick() {
      try {
        this.loading = true
        Object.assign(this.styleForm, { styleIds: [this.modifyStyleInfo.styleId] || [] })
        const { datas } = await skuBoxInfo(this.styleForm)
        this.tableDatas = datas
      } finally {
        this.loading = false
      }
    },
    resetClick() {
      this.$refs.styleForm.resetFields()
      this.queryClick()
    },
    async _StyleChange() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
      const styleId = this.modifyStyleInfo.styleId
      const data = this.styleOptions.find(item => item.id === styleId) || []
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      console.log('datas: ', datas)
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">

// // 不使用transfer， 可以修改以下样式即可
// /deep/ .vxe-select--panel {
//   position: fixed !important;
//   min-width: 10% !important;
//   left: auto !important;
// }

</style>
