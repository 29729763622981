<template>
  <div>
    <el-dialog
      title="版本记录"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" v-model="form">
        <el-form-item label="修改项目：">
          <el-select
            v-model="form.modify"
            filterable
            @change="getModify"
          >
            <el-option
              v-for="item in modifyOptions"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <Table
        ref="multipleTable"
        :table-data="tableDatas"
        max-height="400px"
        :columns="templateTableColumns"
        border
      >
        <el-table-column v-if="form.modify === '3'" slot="sku" label="SKU" width="120" align="center">
          <template slot-scope="{row}">
            {{ row.sku }}
          </template>
        </el-table-column>
      </Table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">关闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { updateInfo } from '@/api/package'
import { templateTableColumns } from './columns'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Table from '@/components/Table'
export default {
  components: { Table },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    versionId: {
      type: Number,
      default: 0
    },
    versionDatas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      templateTableColumns,
      form: { modify: '1' },
      tableDatas: [],
      modifyOptions: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(val) {
      if (!val) {
        this.$refs.uploadForm?.clearFiles()
      }
    },
    'versionDatas'(val) {
      if (val) {
        this.tableDatas = this.versionDatas
        this.form.modify = '1'
      }
    }

  },
  created() {

  },
  mounted() {
    this.modifyOptions = this._getAllCommodityDict('SCM_PACKAGING_STYLE_MODIFY_ITEM')
  },
  methods: {
    getModify() {
      this.getUpdateInfo()
    },
    async getUpdateInfo() {
      const { modify } = this.form
      const { datas } = await updateInfo(this.versionId, modify)
      this.tableDatas = datas
    }
  }
}
</script>

<style scoped lang="scss">
</style>
