<template>
  <div>
    <el-dialog
      title="查看内里标"
      :visible.sync="visible"
      width="80%"
      :close-on-click-modal="false"
    >
      <el-image class="mr-5" :src="img1" style="width:240px;height:350px" />
      <el-image :src="img2" style="width:350px;height:350px" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">关闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    insideDatas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      img1: '',
      img2: ''
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'value'(val) {
      if (!val) {
        this.$refs.uploadForm?.clearFiles()
      }
    },
    'insideDatas'(val) {
      if (val) {
        console.log('val: ', val)
        this.img1 = this.insideDatas?.[0]
        this.img2 = this.insideDatas?.[1]
      }
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
</style>
