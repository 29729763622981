<template>
  <div>
    <el-dialog
      title="修改内里标"
      :visible.sync="visible"
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form ref="form">
        <el-form-item label="附件">
          <el-upload
            ref="uploadForm"
            action=""
            :limit="1"
            show-file-list
            accept=" .xls, .xlsx"
            :file-list="fileList"
            :http-request="handleUploadFile"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-button type="text" @click="downTemplate">导入模板下载</el-button>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handleImport">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { downloadTemplate, importTemplate } from '@/api/package'
import { uploadByPreUrlApi } from '@/api/scm-api'
import { handleDownload } from '@/utils'
import axios from 'axios'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    styleIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [],
      file: '',
      loading: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  watch: {
    'value'(val) {
      if (!val) {
        this.$refs.uploadForm?.clearFiles()
        this.fileList = []
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    // 模板下载
    async downTemplate() {
      const datas = await downloadTemplate(this.styleIds)
      console.log('this.styleIds: ', this.styleIds)
      handleDownload(datas, '修改内里标模板.xlsx')
    },

    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 100
      if (!isLt2M) {
        this.$message.error(`上传失败，文件需小于100M`)
        return false
      }
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['xls', 'xlsx']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 xls、xlsx格式')
        return false
      }
      return true
    },
    async handleUploadFile(options) {
      this.file = options?.file
      this.uploadLoading = true
      const { name: fileName, size: kbSize } = options.file
      const params = {
        fileName,
        kbSize: Math.floor(kbSize / 1024),
        fileType: fileName.substring(fileName.lastIndexOf('.') + 1),
        configCode: 'vendorOfferAttachment'
      }
      const { datas } = await uploadByPreUrlApi(params)
      const {
        originName: name,
        objectName: key,
        policy,
        accessid: OSSAccessKeyId,
        callback,
        signature,
        host: url
      } = datas
      const form = Object.assign(
        {},
        { name, key, policy, OSSAccessKeyId, callback, signature },
        { file: options?.file }
      )
      const formData = new FormData()
      Object.keys(form).forEach((key) => formData.set(`${key}`, form[key]))
      const { data: { datas: response }} = await axios({
        method: 'post',
        url,
        data: formData
      })
      response && options.onSuccess(response) // 成功之后取值
    },
    handleSuccess(response) {
      console.log('response: ', response)
      // response && this.fileList.push(response?.objectName)
    },
    handleRemove(val) {
      const index = this.fileList.findIndex((item) => item.uid === val.uid)
      console.log('index: ', this.fileList, val)
      this.fileList.splice(index, 1)
      this.file = ''
    },
    async handleImport() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)
        if (!this.file) return this.$message.warning('暂无可导入的文件')
        await importTemplate(formData)
        this.$emit('input', false)
        this.$emit('update')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.inline {
  display: inline-block !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
