export const getColumns = [
  {
    prop: 'vendorNames',
    label: '供应商',
    width: 150,
    overflow: true
  },
  {
    prop: 'brandName',
    label: '品牌',
    width: 150
  },
  {
    slot: 'styleName',
    label: 'Style',
    width: '120'
  },
  {
    slot: 'sampleUrl',
    label: '样品图',
    width: '120'

  },
  {
    prop: 'packagingCode',
    label: '包装代码',
    width: '100'
  },
  {
    prop: 'typeDictI18',
    label: '包装类型',
    width: '100'
  },
  {
    prop: 'shoeFoldFlag',
    label: '鞋是否折叠',
    width: '100'
  },
  {
    slot: 'insideLabelDictI18',
    label: '内里标',
    width: '100'
  },

  {
    prop: 'shoeTreeStandardDictI18',
    label: '楦型',
    width: '100',
    formatter: (row) => {
      return row.shoeTreeStandardDictI18 !== 'null' ? row.shoeTreeStandardDictI18 : ''
    }
  },
  {
    prop: 'styleStatus',
    label: 'Style状态',
    width: '100',
    formatter: (row) => {
      return row.styleStatus ? row.styleStatus === '1' ? '启用' : '禁用' : ''
    }
  },
  {
    prop: 'modifyByName',
    label: '操作人',
    width: '120'
  },
  {
    prop: 'modifyTime',
    label: '操作时间',
    width: '160'
  },
  {
    prop: 'createTime',
    label: '产品建档时间',
    width: '160'
  },

  {
    slot: 'operate',
    label: '操作',
    width: '100'
  },
  {
    slot: 'version',
    label: '版本记录',
    width: '100'
  }
]

export const templateTableColumns = [
  { slot: 'sku' },
  { label: '修改者', prop: 'createByName' },
  { label: '修改类型', prop: 'modifyTypeDictI18' },
  { label: '修改时间', prop: 'modifyTime' },
  { label: '修改项目', prop: 'modifyItemDictI18' },
  { label: '修改值', prop: 'value' }
]

export const styleDetailsColumns = [
  { label: 'SKU', prop: 'sku' },
  { label: '颜色', prop: 'color' },
  { label: '尺码', prop: 'size' },
  { label: '双/箱', prop: 'cartonNumber' }
]

