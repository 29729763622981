<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待维护" name="1" />
      <el-tab-pane label="其他数据" name="5" />
    </el-tabs>

    <el-form
      ref="ruleForm"
      :model="form"
      label-width="134px"
      style="display: flex; flex-wrap: wrap; margin-top: 15px"
    >
      <div class="el-lt" style="width: 97%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.styleIds"
                oninput="(/\s+/g, '')"
                value-key="id"
                clearable
                filterable
                multiple
                collapse-tags
                class="style_w100"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="内里标" :class="$i18n.locale">
              <el-select
                v-model="form.insideLabelDicts"
                class="style_w100"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_INSIDE_LABEL')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="包装代码" :class="$i18n.locale">
              <el-select
                v-model="form.packagingCodeIds"
                class="style_w100"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="(item,index) in packagingCodeOptions"
                  :key="index"
                  :label="item.packagingCode"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="鞋是否折叠">
              <el-select v-model="form.shoeFoldFlagList" :placeholder="$t('page.selectPlaceholder')" class="style_w100" filterable multiple>
                <el-option label="Y" value="Y" />
                <el-option label="N" value="N" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorIds"
                multiple
                collapse-tags
                filterable
                clearable
                style="width:100%;"
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品牌" :class="$i18n.locale">
              <el-select
                v-model="form.brandIds"
                class="style_w100"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="楦型" :class="$i18n.locale">
              <el-select
                v-model="form.shoeTreeStandards"
                class="style_w100"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in showTreeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="包装类型">
              <el-select v-model="form.typeDictList" class="style_w100" :placeholder="$t('page.selectPlaceholder')" clearable multiple>
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_PACKAGING_TYPE')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.operateTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品建档时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.styleStartTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 3%; text-align: right">
        <el-button type="text" @click="showRow = !showRow">{{
          showRow ? $t("page.hide") : $t("page.expand")
        }}</el-button>
      </el-row>
      <div class="el-rt header_btn">
        <div>
          <el-button
            v-permission="'edit'"
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="handleModify"
          >
            修改包装信息
          </el-button>
          <el-button
            v-permission="'import'"
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="handleUpload"
          >
            导入模板
          </el-button>
        </div>

        <div class="row-center">
          <el-button
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="queryClick(1)"
          >{{ $t("page.search") }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button
            v-permission="'export'"
            type="primary"
            class="ml-4"
            :loading="exportLoading"
            @click="_exportDetail"
          >导出</el-button>
        </div>
      </div>
    </el-form>

    <Table
      ref="multipleTable"
      :table-data="tableDatas"
      max-height="600px"
      :columns="getColumns"
      :page-obj="pager"
      :check="true"
      :loading="tableLoading"
      border
      @handleSelectionChange="val=>multipleSelection = val"
    >
      <el-table-column slot="insideLabelDictI18" label="内里标" width="100" align="center">
        <template slot-scope="{row}">
          <div style="color: #1890ff" @click="showStandard(row)">{{ row.insideLabelDictI18 !== 'null' ? row.insideLabelDictI18 : '' }}</div>
        </template>
      </el-table-column>
      <el-table-column slot="styleName" label="Style" width="120" align="center">
        <template slot-scope="{row}">
          <div style="color: #1890ff" @click="showStyle(row)">{{ row.styleName }}</div>
        </template>
      </el-table-column>
      <el-table-column slot="sampleUrl" label="样品图" width="120" align="center">
        <template slot-scope="{row}">
          <el-image v-if="row.sampleUrl" style="height: 80px;" fit="contain" :src="row.sampleUrl[0]" />
          <span v-else />
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" width="100" align="center">
        <template slot-scope="{row}">
          <el-button
            v-permission="'toModify'"
            :loading="loading"
            type="text"
            size="small"
            @click="toModifyDetail(row)"
          >
            维护双/箱
          </el-button>
          <el-button
            v-permission="'sendEmail'"
            :loading="loading"
            type="text"
            size="small"
            @click="toSend(row)"
          >
            发送邮件
          </el-button>
        </template>
      </el-table-column>
      <el-table-column slot="version" label="版本记录" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            v-permission="'version'"
            :loading="loading"
            type="text"
            size="small"
            @click="showVersion(scope.row)"
          >
            版本记录
          </el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end :page-sizes="pageSizes" @pagination="_purchasePage" />
    </div>
    <Modify v-model="importVisible" :style-ids="ModifystyleIds" @update="queryClick" />
    <Standard v-model="standardVisible" :inside-datas="insideDatas" />
    <Version v-model="versionVisible" :version-datas="versionDatas" :version-id="versionId" />
    <StyleDetails v-model="styleVisible" :get-style-info="getStyleInfo" :style-datas="styleDatas" />
    <ModifyStyleDetails v-model="modifyStyleVisible" :modify-style-info="modifyStyleInfo" :modify-style-datas="modifyStyleDatas" @update="queryClick" />
    <UploadTemplate v-model="uploadVisible" :style-ids="styleIds" @update="queryClick" />
  </div>
</template>
<script>
import { queryStyleList, queryVendorList } from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'
import { packagingInfo, updateInfo, skuBoxInfo, insideLabel, exportTemplate, getPackagingCode, sendEmail } from '@/api/package'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import { getColumns } from './components/columns'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { omit } from 'lodash'
import Modify from './components/Modify.vue'
import Standard from './components/InternalStandard.vue'
import Version from './components/Version.vue'
import StyleDetails from './components/StyleDetails.vue'
import ModifyStyleDetails from './components/ModifyStyleDetails.vue'
import UploadTemplate from './components/Upload.vue'

export default {
  components: {
    Paging,
    Table,
    Modify,
    Standard,
    Version,
    StyleDetails,
    ModifyStyleDetails,
    UploadTemplate
  },
  mixins: [commodityInfoDict],
  data() {
    return {
      getColumns,
      pageSizes: [10, 20, 30, 40, 50],
      brandOptions: [],
      styleOptions: [],
      vendorOptions: [],
      form: {
        styleIds: [],
        insideLabelDicts: [],
        packagingCodeIds: [],
        vendorIds: [],
        brandIds: [],
        shoeTreeStandards: [],
        operateTime: [],
        styleStartTime: []
      },
      showRow: false,
      tableLoading: false,
      loading: false,
      exportLoading: false,
      activeName: '1',
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableDatas: [],
      packagingCodeOptions: [],
      multipleSelection: [],
      importVisible: false,
      standardVisible: false,
      insideDatas: [],
      versionVisible: false,
      versionDatas: [],
      versionId: 0,
      styleVisible: false,
      getStyleInfo: {},
      styleDatas: [],
      modifyStyleVisible: false,
      modifyStyleInfo: {},
      modifyStyleDatas: [],
      uploadVisible: false,
      ModifystyleIds: [],
      styleIds: [],
      showTreeOptions: [{ label: '美码', value: '1' }, { label: '欧码', value: '2' }]
    }
  },
  computed: {
    queryParams() {
      const { operateTime, styleStartTime, insideLabelDicts, packagingCodeIds, shoeTreeStandards } = this.form
      const [startCreateTime, endCreateTime] = operateTime || []
      const [startStyleCreateTime, endStyleCreateTime] = styleStartTime || []
      const maintained = this.activeName === '5'
      const unMaintain = this.activeName === '1'
      return Object.assign({},
        omit(this.form, ['operateTime', 'styleStartTime']), {
          maintained, unMaintain,
          startCreateTime, endCreateTime,
          startStyleCreateTime,
          endStyleCreateTime,
          insideLabelDicts: insideLabelDicts !== '' && insideLabelDicts ? [insideLabelDicts] : [],
          packagingCodeIds: packagingCodeIds !== '' && packagingCodeIds ? [packagingCodeIds] : [],
          shoeTreeStandards: shoeTreeStandards !== '' && shoeTreeStandards ? [shoeTreeStandards] : []
        })
    }
  },
  mounted() {
    this._queryStyleList()
    this._brandList()
    this._getPackagingCode()
    this._queryVendorList()
    this.queryClick()
  },
  methods: {
    handleUpload() {
      this.uploadVisible = true
      if (this.multipleSelection.length) {
        this.styleIds = this.multipleSelection.map(item => item.styleId)
      }
    },
    handleModify() {
      if (!this.multipleSelection.length) return this.$message.warning('至少选择一条数据')
      this.ModifystyleIds = this.multipleSelection.map(item => item.styleId)
      this.importVisible = true
    },
    async showStyle(row) {
      const params = Object.assign({}, { styleIds: [row.styleId] || [] })
      const { datas } = await skuBoxInfo(params)
      this.styleDatas = datas
      this.getStyleInfo = row
      this.styleVisible = true
    },
    async toModifyDetail(row) {
      const params = Object.assign({}, { styleIds: [row.styleId] || [] })
      const { datas } = await skuBoxInfo(params)
      this.modifyStyleDatas = datas
      this.modifyStyleInfo = row
      this.modifyStyleVisible = true
    },
    toSend(row) {
      if (!row.packagingCode) return this.$message.warning('包装代码为空，无法发送邮件')
      if (row.styleStatus !== '1') return this.$message.warning('Style未启用，无法发送邮件')
      this.$confirm('点击确认会将最新的包装信息发送给对应的供应商，请确认', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(async() => {
        try {
          this.loading = true
          const { packagingCode, styleId, styleName } = row
          const params = {}
          Object.assign(params, { packagingCode, styleId, styleName })
          await sendEmail(params)
          // 调用邮件接口
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } finally {
          this.loading = false
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async showVersion(row) {
      try {
        this.loading = true
        const { datas } = await updateInfo(row.styleId, 1)
        this.versionDatas = datas
        this.versionId = row.styleId
        this.versionVisible = true
      } finally {
        this.loading = false
      }
    },
    async showStandard(row) {
      const { datas } = await insideLabel({ insideLabelDict: row.insideLabelDict })
      this.insideDatas = datas
      this.standardVisible = true
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // brand
    async _brandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _getPackagingCode() {
      const { datas } = await getPackagingCode()
      this.packagingCodeOptions = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    async queryClick(flag) {
      try {
        this.loading = true
        this.tableLoading = true
        flag && flag === 1 ? (this.pager.current = 1) : ''
        const {
          datas: { pager, records }
        } = await packagingInfo(this.queryParams, this.pager)
        this.pager = pager
        this.tableDatas = records
        this.loading = false
        this.tableLoading = false
      } finally {
        this.loading = false
        this.tableLoading = false
      }
    },
    async _exportDetail() {
      const { brandIds, insideLabelDicts, packagingCodeIds, shoeTreeStandards, styleIds, vendorIds, operateTime, styleStartTime } = this.form
      if (!this.multipleSelection.length && !brandIds.length && !insideLabelDicts.length && !packagingCodeIds.length && !shoeTreeStandards.length && !styleIds.length && !vendorIds.length && !operateTime.length && !styleStartTime.length) {
        this.$confirm('全部数据导出缓慢，不建议导出，请确认是否导出', '确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false
        }).then(async() => {
          try {
            this.exportLoading = true
            await exportTemplate(this.queryParams)
            this.$notify({
              message: '操作成功，请前往文件导出界面下载',
              type: 'success'
            })
          } finally {
            this.exportLoading = false
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else {
        try {
          this.exportLoading = true
          if (this.multipleSelection.length) {
            const styleIds = this.multipleSelection.map(item => item.styleId)
            await exportTemplate({ ...this.queryParams, styleIds })
          } else {
            await exportTemplate(this.queryParams)
          }
          this.$notify({
            message: '操作成功，请前往文件导出界面下载',
            type: 'success'
          })
        } finally {
          this.exportLoading = false
        }
      }
    },
    resetClick() {
      this.form = {}
      this.activeName = '1'
      this.queryClick(1)
    },

    async handleClick(tab, event) {
      this.queryClick(1)
      console.log('rrr', tab, event)
    },

    async _purchasePage(pagers) {
      this.pager = pagers
      this.queryClick()
    }
  }
}
</script>
<style lang="scss" scope>

.style_w100 {
  width: 100%;
}
.header_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.header_replenishCycle {
  margin-bottom: 0
}

</style>
